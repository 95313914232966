// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.scope-ui {
  position: relative;
  flex: 1 1 auto;
  min-height: 100px;
}
div.scope-ui > .scope-controller {
  position: absolute;
  display: flex;
  top: 0px;
  z-index: 5;
  opacity: 0.4;
  transition: opacity 0.15s ease-in-out;
}
div.scope-ui:hover > .scope-controller {
  opacity: 1;
}
div.scope-ui > canvas {
  position: absolute;
  width: 100%;
  height: auto;
}
div.scope-ui .btn-overlay {
  font-size: 12px;
  border-color: transparent;
  border-radius: 0px;
  padding: 0rem 0.1rem;
  z-index: 3;
}
div.scope-ui .btn-overlay.active {
  color: unset !important;
  background-color: unset !important;
  border-color: transparent transparent #f8f9fa transparent !important;
  border-bottom-color: #f8f9fa !important;
}
div.scope-ui .btn-overlay.active:hover {
  color: #212529 !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}
div.scope-ui .btn-overlay:focus {
  box-shadow: none !important;
}
div.scope-ui .btn-overlay.btn-overlay-icon {
  width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Scope.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,cAAA;EACA,iBAAA;AACJ;AAAI;EACI,kBAAA;EACA,aAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;EACA,qCAAA;AAER;AACQ;EACI,UAAA;AACZ;AAEI;EACI,kBAAA;EACA,WAAA;EACA,YAAA;AAAR;AAEI;EACI,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,oBAAA;EACA,UAAA;AAAR;AACQ;EACI,uBAAA;EACA,kCAAA;EACA,oEAAA;EACA,uCAAA;AACZ;AAAY;EACI,yBAAA;EACA,oCAAA;EACA,gCAAA;AAEhB;AACQ;EACI,2BAAA;AACZ;AACQ;EACI,WAAA;AACZ","sourcesContent":["div.scope-ui {\n    position: relative;\n    flex: 1 1 auto;\n    min-height: 100px;\n    & > .scope-controller {\n        position: absolute;\n        display: flex;\n        top: 0px;\n        z-index: 5;\n        opacity: 0.4;\n        transition: opacity 0.15s ease-in-out;\n    }\n    &:hover {\n        & > .scope-controller {\n            opacity: 1;\n        }\n    }\n    & > canvas {\n        position: absolute;\n        width: 100%;\n        height: auto;\n    }\n    & .btn-overlay {\n        font-size: 12px;\n        border-color: transparent;\n        border-radius: 0px;\n        padding: 0rem 0.1rem;\n        z-index: 3;\n        &.active {\n            color: unset !important;\n            background-color: unset !important;\n            border-color: transparent transparent #f8f9fa transparent !important;\n            border-bottom-color: #f8f9fa !important;\n            &:hover {\n                color: #212529 !important;\n                background-color: #f8f9fa !important;\n                border-color: #f8f9fa !important;\n            }\n        }\n        &:focus {\n            box-shadow: none !important;\n        }\n        &.btn-overlay-icon {\n            width: 20px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
